import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateDayCenterRecords } from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";
import moment from "moment";

export const updateRecords = createAsyncThunk(
  "dayCenter/update_records",
  withNotifications(
    updateDayCenterRecords,
    "update_daycenter_records",
    "update_daycenter_records_success",
    "update_daycenter_records_error"
  )
);

const initialState = {
  activeDate: moment().format(),
  activeBranch: null,
  page: {
    current: 1,
    filter: {
      freeText: null,
    },
    order: {},
    items: [],
  },
};

const dayCenterSlice = createSlice({
  name: "dayCenter",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setDate(state, action) {
      const { date } = action.payload;
      state.activeDate = date;
      state.page.current = 1;
    },
    setTextFilter(state, action) {
      const { text } = action.payload;
      state.page = {
        ...state.page,
        filter: {
          ...state.page.filter,
          freeText: text,
        },
      };
    },
    setActiveBranch(state, action) {
      const { branch } = action.payload;
      state.activeBranch = branch;
      state.page.current = 1;
    },
    reset(state, action) {
      state.page = initialState.page;
      state.activeDate = initialState.activeDate;
    },
  },
  extraReducers: {},
});

export const { setPage, setDate, setTextFilter, setActiveBranch, reset } =
  dayCenterSlice.actions;

export default dayCenterSlice.reducer;
