import React, { useEffect } from "react";
import "./index.css";
import "./assets/fonts/Rubik-Regular";
import "./assets/fonts/Rubik-Bold";
import { Provider } from "react-redux";
import store from "./redux/store";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";

function importBuildTarget() {
  if (process.env.REACT_APP_BUILD_TARGET === "tenants") {
    return import("./TenantsApp.js");
  } else {
    return import("./App.js");
  }
}

function getSentryEnv() {
  let hostname = document.location.hostname;
  if (hostname === "localhost") {
    return "development";
  } else if (hostname === "test.a-dreams.com") {
    return "test"
  } else if (hostname.endsWith("a-dreams.com")) {
    return "production"
  }
  return null;
}

let env = getSentryEnv();
console.log("Sentry env" + env);
if (env === "production" || env === "test") {
  console.log("init Sentry env" + env);
  Sentry.init({
    envorinment: env,
    dsn: "https://1d1bbb41e14c042f91458382ca860946@o4507226754973696.ingest.de.sentry.io/4507226900332624",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    //tracePropagationTargets: [/^https:\/\/*.a-dreams.com/],    
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const container = document.getElementById("root");
const root = createRoot(container);

// The new wrapper component that logs "rendered"
function AppWithCallbackAfterRender({ children }) {
  useEffect(() => { }, []);
  return children;
}

// Import the entry point and render it's default export
importBuildTarget().then(({ default: Environment }) =>
  root.render(
    <Provider store={store}>
      <AppWithCallbackAfterRender>
        <Environment />
      </AppWithCallbackAfterRender>
    </Provider>
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
