import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getReport,
  createReport,
  updateReport,
  deleteReport,
} from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeReport: {},
  changedItems: {
    changed: [],
    values: [],
  },
  visState: {},
  queryParams: {},
  dynamicFilters: [],
  executeDynamicFilters: [],
  runTimeFilters: [],
  pdfData: [],
  orientation: "p",
};

export const loadRecord = createAsyncThunk(
  "report/load",
  withNotifications(getReport, "get_report", null, "get_report_error")
);

export const createRecord = createAsyncThunk(
  "report/create",
  withNotifications(
    createReport,
    "create_report",
    "create_report_success",
    "create_report_error"
  )
);

export const updateRecord = createAsyncThunk(
  "report/update",
  withNotifications(
    updateReport,
    "update_report",
    "update_report_success",
    "update_report_error"
  )
);

export const deleteRecord = createAsyncThunk(
  "report/delete",
  withNotifications(
    deleteReport,
    "delete_report",
    "delete_report_success",
    "delete_report_error"
  )
);

const reportSlice = createSlice({
  name: "report",
  initialState: initialState,
  reducers: {
    setActiveReport(state, action) {
      const { record } = action.payload;
      state.activeReport = record;
    },
    setChangedFormItems(state, action) {
      const { changed, values } = action.payload;
      state.changedItems.changed = changed;
      state.changedItems.values = values;
    },
    setVisState(state, action) {
      const { vis } = action.payload;
      state.visState = vis;
    },
    setQueryParams(state, action) {
      const { queryParams } = action.payload;
      state.queryParams = queryParams;
    },
    setDynamicFilters(state, action) {
      state.dynamicFilters = action.payload;
    },
    setExecuteDynamicFilters(state, action) {
      state.executeDynamicFilters = action.payload;
    },
    updateDynamicFilters(state, action) {
      var index = state.executeDynamicFilters
        .map(function (e) {
          return e.id;
        })
        .indexOf(action.payload.id);
      const newFilters = [...state.executeDynamicFilters];
      newFilters[index] = action.payload;
      const filters = newFilters
        .filter((i) => i.values && i.values.length > 0)
        .map((j) => ({
          member: j.member,
          operator: j.operator,
          values: j.values,
        }));
      state.executeDynamicFilters = newFilters;
      state.runTimeFilters = filters;
    },
    updateRunTimeFilters(state, action) {
      state.runTimeFilters = action.payload;
    },
    setPdfData(state, action) {
      const { record } = action.payload;
      state.pdfData = record;
    },
    setOrientation(state, action) {
      const { record } = action.payload;
      state.orientation = record;
    },
    reset(state, action) {
      state.error = null;
      state.activeReport = {};
      state.visState = {};
      state.queryParams = {};
      state.dynamicFilters = [];
      state.executeDynamicFilters = [];
      state.runTimeFilters = [];
      state.changedItems = {
        changed: [],
        values: [],
      };
      state.pdfData = [];
      state.orientation = "p";
    },
  },
  extraReducers: {
    [loadRecord.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.activeReport = data.data;
      state.visState = data.data;
    },
    [loadRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [createRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [createRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [updateRecord.fulfilled]: (state, action) => {
      const { data } = action.payload;
      state.activeReport = data;
      state.visState = data;
    },
    [updateRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [deleteRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const commitChanges = (params) => (dispatch, getState) => {
  const { reportMode } = params;
  const report = getState().report;
  const { visState, activeReport, dynamicFilters } = report;
  const {
    changedItems: { values },
  } = report;
  const categories =
    visState?.categories &&
    !values.categories &&
    visState?.categories?.map((i) => i.id);

  // Create a set of current dimensions for quick lookup
  const currentDimensionsSet = new Set(visState.query.dimensions);

  // Filter and order dimensions based on pivotConfig.x
  const orderedDimensions = visState?.pivotConfig?.x
    ? visState.pivotConfig.x
        .filter((item) => currentDimensionsSet.has(item))
        .concat(
          (visState.query?.dimensions || []).filter(
            (dim) => !visState.pivotConfig.x.includes(dim)
          )
        )
    : visState?.query?.dimensions || [];

  const finalReport = {
    ...visState,
    ...values,
    dynamic_filters: dynamicFilters.map((i) => ({
      member: i.member,
      operator: i.operator,
      name: i.name,
    })),
    query: {
      ...visState.query,
      dimensions: orderedDimensions,
      measures: visState.query.measures || [],
      filters: visState.query.filters || [],
      segments: visState.query.segments || [],
      order: visState.query.order || [],
      timeDimensions: visState.query.timeDimensions || [],
      limit: visState.query.limit ? visState.query.limit : 50000,
    },
    orderMembers: [],
  };
  if (categories) {
    finalReport["categories"] = categories;
  }
  return activeReport.id && !reportMode
    ? dispatch(
        updateRecord({ report: { ...finalReport, id: activeReport.id } })
      )
    : dispatch(createRecord({ report: finalReport }));
};

export const {
  reset,
  setActiveReport,
  setChangedFormItems,
  setVisState,
  setQueryParams,
  setDynamicFilters,
  setExecuteDynamicFilters,
  updateDynamicFilters,
  updateRunTimeFilters,
  setPdfData,
  setOrientation,
} = reportSlice.actions;

export default reportSlice.reducer;
