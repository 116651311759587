import { createSlice } from "@reduxjs/toolkit";
import { listPatientsChangeLogs } from "../../api/server";
import { createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  page: {
    items: [],
    filter: {},
    order: {
      field: "created_at",
      order: "descend",
    },
    pagination: {
      current: 1,
      pageSize: 100,
    },
  },
};

export const loadPage = createAsyncThunk(
  "logs/loadPage",
  async (_, { getState }) => {
    const state = getState();
    const { page } = state.logs;
    const { activePatient } = state.patientDetails;

    const params = {
      patientId: activePatient.id,
      ...page.pagination,
      order: page.order,
      filter: page.filter,
    };

    const response = await listPatientsChangeLogs(params);
    return response;
  }
);

const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    reset: (state) => {
      state.page = initialState.page;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadPage.fulfilled, (state, action) => {
      state.page.items = action.payload.data.items;
      state.page.total = action.payload.data.total;
    });
  },
});

export const { setPage, reset } = logsSlice.actions;
export default logsSlice.reducer;
