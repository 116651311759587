import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { listPatientOrders } from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";
import { withNotifications } from "../notifications/notificationsSlice";

export const fetchPage = createAsyncThunk(
  "orders/loadPage",
  async (listParams, thunkAPI) => {
    console.log(listParams);
    const { error, data } = await listPatientOrders(listParams);
    if (error) {
      thunkAPI.dispatch(notifyError({ title: "Load orders", message: error }));
    }
    return data;
  }
);

export const listOrders = createAsyncThunk(
  "orders/list",
  withNotifications(listPatientOrders, "list_orders", null, "list_orders_error")
);

const onItemChanged = (state, action) => {
  const changedItem = action.payload.data;
  const { items } = state.page;
  state.page.items = items.map((i) => {
    if (i.id === changedItem.id) {
      return changedItem;
    }
    return i;
  });
};
const initialPageSize = 100;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {
      field: "effective_from",
      order: "descend",
    },
    items: [],
  },
  templates: {
    items: [],
  },
};

const ordersSlice = createSlice({
  name: "orders",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    reset(state, action) {
      state.page = initialState.page;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
    "orderDetails/update/fulfilled": onItemChanged,
    "orderDetails/delete/fulfilled": (state, action) => {
      const { items } = state.page;
      state.page.items = items.filter((item) => item.id !== action.meta.arg);
    },
  },
});

export const { setPage, reset } = ordersSlice.actions;

export default ordersSlice.reducer;

export const loadPage = (params) => (dispatch, getState) => {
  const newState = {
    ...getState().orders.page,
    ...params,
  };
  const scopePrefix = getState().user.scopePrefix;
  const patientId = getState().patientDetails.activePatient.id;
  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState, scopePrefix, patientId }));
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().orders.page));
};
