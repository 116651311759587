import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { listTenants, migrateAll, runTenantJob } from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";

export const fetchPage = createAsyncThunk(
  "tenants/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await listTenants(listParams);
    if (error) {
      thunkAPI.dispatch(notifyError({ title: "Load tenants", message: error }));
    }
    return data;
  }
);

export const makeMigration = createAsyncThunk(
  "tenants/migrate",
  async (thunkAPI) => {
    const { error, data } = await migrateAll();
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Make migration", message: error })
      );
    }
    return data;
  }
);

export const runJob = createAsyncThunk(
  "tenants/runJob",
  async (params, thunkAPI) => {
    const { error, data } = await runTenantJob(params);
    if (error) {
      thunkAPI.dispatch(notifyError({ title: "Run job", message: error }));
    }
    return data;
  }
);

const initialPageSize = 100;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      // freeText: null,
    },
    order: {
      field: "company_name",
      order: "ascend",
    },
    items: [],
  },
};

const tenantsSlice = createSlice({
  name: "tenants",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    reset(state, action) {
      state.page = initialState.page;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [makeMigration.fulfilled]: (state, action) => {},
    [makeMigration.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [runJob.fulfilled]: (state, action) => {},
    [runJob.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setPage, reset } = tenantsSlice.actions;

export default tenantsSlice.reducer;

export const loadPage = (params) => (dispatch, getState) => {
  const newState = {
    ...getState().tenants.page,
    ...params,
  };
  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState }));
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().tenants.page));
};
