import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { listBranches } from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";

export const fetchPage = createAsyncThunk(
  "branches/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await listBranches(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load branches", message: error })
      );
    }
    return data;
  }
);

const onItemChanged = (state, action) => {
  const changedItem = action.payload.data;
  const { items } = state.page;
  state.page.items = items.map((i) => {
    if (i.id === changedItem.id) {
      return changedItem;
    }
    return i;
  });
};
const initialPageSize = 10;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {
      field: "name",
      order: "descend",
    },
    items: [],
  },
};

const branchesSlice = createSlice({
  name: "branches",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    reset(state, action) {
      state.page = initialState.page;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
    "branchDetails/activate/fulfilled": onItemChanged,
    "branchDetails/deactivate/fulfilled": onItemChanged,
    "branchDetails/update/fulfilled": onItemChanged,
    "branchDetails/delete/fulfilled": (state, action) => {
      const { items } = state.page;
      state.page.items = items.filter((item) => item.id !== action.meta.arg);
    },
  },
});

export const { setPage, reset } = branchesSlice.actions;

export default branchesSlice.reducer;

export const loadPage = (params) => (dispatch, getState) => {
  const newState = {
    ...getState().branches.page,
    ...params,
  };
  const scopePrefix = getState().user.scopePrefix;
  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState, scopePrefix }));
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().branches.page));
};
