import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  listProspectuses,
  uploadBTLFile,
  getAccountingSummary,
  exportProspectuses as exportProspectusesApi,
} from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";
import moment from "moment";
import { withNotifications } from "../notifications/notificationsSlice";

export const fetchPage = createAsyncThunk(
  "prospectuses/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await listProspectuses(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load prospectuses", message: error })
      );
    }
    return data;
  }
);

export const fetchSummary = createAsyncThunk(
  "prospectuses/fetchSummary",
  async (params, thunkAPI) => {
    const action = await getAccountingSummary(params);
    // if (error) {
    //   thunkAPI.dispatch(
    //     notifyError({ title: "Load prospectuses summary", message: error })
    //   );
    // }
    return action;
  }
);

export const uploadBtlProspectusFile = createAsyncThunk(
  "prospectus/uploadBtlFile",
  withNotifications(
    uploadBTLFile,
    "upload_btl_file",
    "upload_btl_file_success",
    "upload_btl_file_error"
  )
);

export const exportProspectuses = createAsyncThunk(
  "prospectus/export",
  withNotifications(
    exportProspectusesApi,
    "export_prospectuses",
    "export_prospectuses_success",
    "export_prospectuses_error"
  )
);

const initialPageSize = 100;
const initialState = {
  activeYear: moment().subtract(1, "months").format("YYYY"),
  activeMonth: moment().subtract(1, "months").format("M"),
  activeClient: null,
  textFilter: null,
  activeBranch: null,
  summary: {},
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {
      field: "serial_number",
      order: "descend",
    },
    items: [],
  },
};

const prospectusesSlice = createSlice({
  name: "prospectuses",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setYear(state, action) {
      const { year } = action.payload;
      state.activeYear = year;
    },
    setMonth(state, action) {
      const { month } = action.payload;
      state.activeMonth = month;
    },
    setClient(state, action) {
      const { client } = action.payload;
      state.activeClient = client;
    },
    setBranch(state, action) {
      const { branch } = action.payload;
      state.activeBranch = branch;
    },
    setTextFilter(state, action) {
      const { text } = action.payload;
      state.textFilter = text;
    },
    resetSummary(state, action) {
      state.summary = initialState.summary;
    },
    resetProspectuses(state, action) {
      state.activeYear = initialState.activeYear;
      state.activeMonth = initialState.activeMonth;
      state.activeBranch = initialState.activeBranch;
      state.activeClient = initialState.activeClient;
      state.page = initialState.page;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [fetchSummary.fulfilled]: (state, action) => {
      state.summary = action.payload;
    },
    [fetchSummary.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [uploadBtlProspectusFile.fulfilled]: (state, action) => {},
    [uploadBtlProspectusFile.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setPage,
  setYear,
  setMonth,
  setClient,
  setBranch,
  setTextFilter,
  resetProspectuses,
  resetSummary,
} = prospectusesSlice.actions;

export default prospectusesSlice.reducer;

export const loadPage = (params, query) => (dispatch, getState) => {
  const dateParams =
    getState().prospectuses.activeYear && getState().prospectuses.activeMonth
      ? [
          { year: { $eq: +getState().prospectuses.activeYear } },
          { month: { $eq: +getState().prospectuses.activeMonth } },
        ]
      : [];

  const newFilter =
    params && params.filter.$and
      ? {
          $and: [
            ...params.filter.$and.filter((i) => !i["year"] && !i["month"]),
            ...dateParams,
          ],
        }
      : {
          $and: dateParams,
        };
  const newState = {
    ...getState().prospectuses.page,
    ...params,
    filter: {
      ...newFilter,
      freeText: getState().prospectuses.textFilter,
    },
  };
  const branchPath = !getState().prospectuses.activeBranch
    ? ""
    : `branches/${getState().prospectuses.activeBranch}/`;
  const path = `${branchPath}accounting/prospectuses`;
  const scopePrefix = getState().user.scopePrefix;
  dispatch(setPage(newState));
  if (path) {
    dispatch(fetchPage({ ...newState, scopePrefix, query, path }));
  }
  let summaryParams = {
    scope:
      getState().user.scopePrefix === "" &&
      !getState().prospectuses.activeBranch
        ? null
        : getState().prospectuses.activeBranch
        ? `branches/${getState().prospectuses.activeBranch}/`
        : getState().user.scopePrefix,
  };
  if (
    getState().prospectuses.activeYear &&
    getState().prospectuses.activeMonth
  ) {
    summaryParams["year"] = +getState().prospectuses.activeYear;
    summaryParams["month"] = +getState().prospectuses.activeMonth;
  }
  if (summaryParams.scope) {
    dispatch(fetchSummary(summaryParams));
  } else {
    dispatch(resetSummary());
  }
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().prospectuses.page));
};
