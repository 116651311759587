/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

let notificationIndex = 0;

const notificationsSlice = createSlice({
  name: "notifications",
  initialState: {
    notifications: [],
  },
  reducers: {
    notifySuccess: (state, { payload: { title, message } }) => {
      state.notifications.push({
        type: "success",
        id: notificationIndex++,
        title,
        message,
      });
    },
    notifyError: (state, { payload: { title, message, extra_data } }) => {
      state.notifications.push({
        type: "error",
        id: notificationIndex++,
        title,
        message,
        extra_data,
      });
    },
    clearNotification: (state, action) => {
      state.notifications = state.notifications.filter(
        (n) => n.id !== action.payload
      );
    },
    clearNotifications: (state) => {
      state.last = {};
    },
  },
});

export const {
  notifySuccess,
  notifyError,
  clearNotifications,
  clearNotification,
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const withNotifications =
  (fn, title, message, errorTitle) => (arg, thunkAPI) => {
    return fn(arg, thunkAPI)
      .then((res) => {
        const { error } = res;
        if (error) {
          thunkAPI.dispatch(notifyError({ title, message: error }));
          return res;
        }
        if (message != null) {
          thunkAPI.dispatch(notifySuccess({ title, message }));
        }
        return res;
      })
      .catch((err) => {
        if (err && err.error) {
          thunkAPI.dispatch(
            notifyError({
              title: errorTitle || title,
              message: err.error,
              extra_data: err.message,
            })
          );
          throw err.error;
        }
        thunkAPI.dispatch(
          notifyError({ title: errorTitle || title, message: err })
        );
        throw err;
      });
  };
