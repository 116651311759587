import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addEntityAsset,
  deleteAsset as deleteAssetApi,
  updateAsset as updateAssetApi,
  restoreAsset as restoreAssetApi,
  sendAsset as sendAssetApi,
  upload,
  generateUploadURL,
} from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeAsset: {},
  changedItems: {
    changed: [],
    values: [],
  },
};

export const addAsset = createAsyncThunk(
  "asset/add",
  withNotifications(
    async ({ resource, branchId, id: resourceID, file, params }) => {
      const {
        data: { url },
      } = await generateUploadURL(
        resource,
        branchId,
        resourceID,
        params.name,
        file
      );
      await upload(url, file);
      return addEntityAsset(resource, branchId, resourceID, url, params);
    },
    "add_asset",
    "add_asset_success",
    "add_asset_error"
  )
);

export const updateAsset = createAsyncThunk(
  "asset/update",
  withNotifications(
    async (params, thunkAPI) => {
      const { id, scope } = params;
      const { asset } = thunkAPI.getState();

      const {
        changedItems: { changed, values },
      } = asset;
      const toUpdate = changed.reduce((acc, key) => {
        acc[key] = values[key] === undefined ? null : values[key];
        return acc;
      }, {});
      return updateAssetApi({
        id,
        scope,
        values: toUpdate,
      });
    },
    "update_asset",
    "update_asset_success",
    "update_asset_error"
  )
);

export const deleteAsset = createAsyncThunk(
  "asset/delete",
  withNotifications(
    deleteAssetApi,
    "delete_asset",
    "delete_asset_success",
    "delete_asset_error"
  )
);

export const restoreAsset = createAsyncThunk(
  "asset/restore",
  withNotifications(
    restoreAssetApi,
    "restore_asset",
    "restore_asset_success",
    "restore_asset_error"
  )
);

export const sendAsset = createAsyncThunk(
  "asset/send",
  withNotifications(
    sendAssetApi,
    "send_asset",
    "send_asset_success",
    "send_asset_error"
  )
);

const assetSlice = createSlice({
  name: "asset",
  initialState: initialState,
  reducers: {
    setActiveAsset(state, action) {
      const { record } = action.payload;
      state.activeAsset = record;
    },
    setChangedFormItems(state, action) {
      const { changed, values } = action.payload;
      state.changedItems.changed = changed;
      state.changedItems.values = values;
    },
    reset(state, action) {
      state.error = null;
      state.activeAsset = {};
    },
  },
  extraReducers: {
    [addAsset.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [addAsset.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteAsset.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [deleteAsset.rejected]: (state, action) => {
      state.error = action.error;
    },
    [restoreAsset.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [restoreAsset.rejected]: (state, action) => {
      state.error = action.error;
    },
    [updateAsset.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [updateAsset.rejected]: (state, action) => {
      state.error = action.error;
    },
    [sendAsset.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [sendAsset.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const {
  setActiveAsset,
  setChangedFormItems,
  reset,
} = assetSlice.actions;

export default assetSlice.reducer;
