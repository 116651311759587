import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateDayCenterRecords } from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";
import moment from "moment";

export const updateRecords = createAsyncThunk(
  "daycenterpatient/update_records",
  withNotifications(
    updateDayCenterRecords,
    "update_daycenterpatient_records",
    "update_daycenterpatient_records_success",
    "update_daycenterpatient_records_error"
  )
);

const initialPageSize = 50;
const initialState = {
  activeDate: moment().format(),
  activePatient: {},
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {},
    items: [],
  },
};

const dayCenterPatientSlice = createSlice({
  name: "dayCenterPatient",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setActivePatient(state, { payload: patient }) {
      state.activePatient = patient;
    },
    setDate(state, action) {
      const { date } = action.payload;
      state.activeDate = date;
    },
    setTextFilter(state, action) {
      const { text } = action.payload;
      state.page = {
        ...state.page,
        filter: {
          ...state.page.filter,
          freeText: text,
        },
      };
    },
    reset(state, action) {
      state.page = initialState.page;
      state.activeDate = initialState.activeDate;
    },
  },
});

export const { setPage, setDate, setTextFilter, reset, setActivePatient } =
  dayCenterPatientSlice.actions;

export default dayCenterPatientSlice.reducer;
