import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  listEmployeesSalary,
  listEmployeesSalaryLines,
  createSalaryLine,
  updateSalaryLine,
  deleteSalaryLine,
  approveEmployeeSalary,
  recalculateEmployeeSalary,
  lockSalary,
  unlockSalary,
  runMonthSalary,
  approveAllMonthSalary,
  exportSalaryFile,
} from "../../api/server";
import { withNotifications } from "../notifications/notificationsSlice";
import { notifyError } from "../notifications/notificationsSlice";
import moment from "moment";

export const fetchPage = createAsyncThunk(
  "salary/loadPage",
  async (listParams, thunkAPI) => {
    const timestamp = moment().format();
    thunkAPI.dispatch(setLastQueryTimestamp({ timestamp }));
    const { error, data } = await listEmployeesSalary(listParams);
    const lastTimestamp = thunkAPI.getState().salary.lastQueryTimestamp;
    if (error) {
      thunkAPI.dispatch(notifyError({ title: "Load salary", message: error }));
    }
    if (timestamp === lastTimestamp) {
      return data;
    }
  }
);

export const fetchSalaryLines = createAsyncThunk(
  "salaryLine/load",
  async (listParams, thunkAPI) => {
    const { error, data } = await listEmployeesSalaryLines(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load salary lines", message: error })
      );
    }
    return data;
  }
);

export const createRecord = createAsyncThunk(
  "salaryLine/create",
  withNotifications(
    createSalaryLine,
    "create_salaryLine",
    "create_salaryLine_success",
    "create_salaryLine_error"
  )
);

export const updateRecord = createAsyncThunk(
  "salaryLine/update",
  withNotifications(
    updateSalaryLine,
    "update_salaryLine",
    "update_salaryLine_success",
    "update_salaryLine_error"
  )
);

export const deleteRecord = createAsyncThunk(
  "salaryLine/delete",
  withNotifications(
    deleteSalaryLine,
    "delete_salaryLine",
    "delete_salaryLine_success",
    "delete_salaryLine_error"
  )
);

export const approveEmployee = createAsyncThunk(
  "salary/approve_employee",
  withNotifications(
    approveEmployeeSalary,
    "approve_employee",
    "approve_employee_success",
    "approve_employee_error"
  )
);

export const unapproveEmployee = createAsyncThunk(
  "salary/unapprove_employee",
  withNotifications(
    recalculateEmployeeSalary,
    "unapprove_employee",
    "unapprove_employee_success",
    "unapprove_employee_error"
  )
);

export const recalculateEmployee = createAsyncThunk(
  "salary/recalculate_employee",
  withNotifications(
    recalculateEmployeeSalary,
    "recalculate_employee",
    "recalculate_employee_success",
    "recalculate_employee_error"
  )
);

export const runMonthlySalary = createAsyncThunk(
  "salary/run_salary",
  withNotifications(
    runMonthSalary,
    "run_salary",
    "run_salary_success",
    "run_salary_error"
  )
);

export const approveAllMonthlySalary = createAsyncThunk(
  "salary/approve_all_salary",
  withNotifications(
    approveAllMonthSalary,
    "approve_all_salary",
    "approve_all_salary_success",
    "approve_all_salary_error"
  )
);

export const lockMonthlySalary = createAsyncThunk(
  "salary/lock",
  withNotifications(
    lockSalary,
    "lock_salary",
    "lock_salary_success",
    "lock_salary_error"
  )
);

export const unlockMonthlySalary = createAsyncThunk(
  "salary/unlock",
  withNotifications(
    unlockSalary,
    "unlock_salary",
    "unlock_salary_success",
    "unlock_salary_error"
  )
);

export const exportSalary = createAsyncThunk(
  "salary/export",
  withNotifications(
    exportSalaryFile,
    "export_salary",
    "export_salary_success",
    "export_salary_error"
  )
);

const initialPageSize = 10;
const initialState = {
  activeYear: moment().subtract(1, "months").format("YYYY"),
  activeMonth: moment().subtract(1, "months").format("M"),
  activeBranch: null,
  activeClient: null,
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {},
    items: [],
    summary: {},
  },
  customSort: null,
  salaryLines: {
    current: 1,
    pageSize: 100,
    filter: {
      freeText: null,
    },
    order: {},
    items: [],
    lastQueryTimestamp: null,
  },
};

const salarySlice = createSlice({
  name: "salary",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setSalaryLines(state, { payload: salaryLines }) {
      state.salaryLines = salaryLines;
    },
    setCustomSort(state, { payload: customSort }) {
      state.customSort = customSort;
    },
    resetSalary(state, action) {
      state.activeYear = initialState.activeYear;
      state.activeMonth = initialState.activeMonth;
      state.activeBranch = initialState.activeBranch;
      state.activeClient = initialState.activeClient;
      state.page = initialState.page;
      state.salaryLines = initialState.salaryLines;
      state.customSort = initialState.customSort;
    },
    resetSalaryLines(state, action) {
      state.salaryLines.items = [];
    },
    setYear(state, action) {
      const { year } = action.payload;
      state.activeYear = year;
    },
    setMonth(state, action) {
      const { month } = action.payload;
      state.activeMonth = month;
    },
    setBranch(state, action) {
      const { branch } = action.payload;
      state.activeBranch = branch;
    },
    setClient(state, action) {
      const { client } = action.payload;
      state.activeClient = client;
    },
    setTextFilter(state, action) {
      const { text } = action.payload;
      state.page = {
        ...state.page,
        filter: {
          ...state.page.filter,
          freeText: text,
        },
      };
    },
    setLastQueryTimestamp(state, action) {
      const { timestamp } = action.payload;
      state.lastQueryTimestamp = timestamp;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [fetchSalaryLines.fulfilled]: (state, action) => {
      state.salaryLines = { ...state.salaryLines, ...action.payload };
    },
    [fetchSalaryLines.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [updateRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [updateRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [createRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [createRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [deleteRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [approveEmployee.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [approveEmployee.rejected]: (state, action) => {
      state.error = action.error;
    },
    [unapproveEmployee.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [unapproveEmployee.rejected]: (state, action) => {
      state.error = action.error;
    },
    [recalculateEmployee.fulfilled]: (state, { payload: { error, data } }) => {
      state.error = error;
    },
    [recalculateEmployee.rejected]: (state, action) => {
      state.error = action.error;
    },
  },
});

export const {
  setPage,
  setCustomSort,
  setSalaryLines,
  resetSalary,
  resetSalaryLines,
  setYear,
  setMonth,
  setBranch,
  setClient,
  setTextFilter,
  setLastQueryTimestamp,
} = salarySlice.actions;

export default salarySlice.reducer;

export const changePage = (params, query, isAdmin) => (dispatch, getState) => {
  const { finalfilters, order } = params;
  let newParams = params;
  if (order && order.field.includes("custom_sort")) {
    newParams = { ...params, order: {} };
    dispatch(setCustomSort(order));
  } else {
    dispatch(setCustomSort(null));
  }
  if (finalfilters) {
    const otherFilters = params.filter["$and"] ? params.filter["$and"] : [];
    newParams.filter = {
      $and: [...otherFilters],
      freeText: getState().salary.page.filter.freeText,
    };
  }
  const newState = {
    ...getState().salary.page,
    ...newParams,
  };
  dispatch(setPage(newState));
};

export const loadPage = (isAdmin) => (dispatch, getState) => {
  const query = {
    year: +getState().salary.activeYear,
    month: +getState().salary.activeMonth,
    sort:
      getState().salary.customSort &&
      getState().salary.customSort.field.split("custom_sort")[1],
    asc:
      getState().salary.customSort &&
      getState().salary.customSort.order === "ascend",
  };
  const path = isAdmin
    ? `branches/${getState().salary.activeBranch}/salary`
    : "salary";
  const scopePrefix = getState().user.scopePrefix;
  if (!isAdmin || (isAdmin && getState().salary.activeBranch)) {
    dispatch(
      fetchPage({ ...getState().salary.page, scopePrefix, path, query })
    );
  }
};

export const loadSalaryLines =
  (isAdmin, employeeId) => (dispatch, getState) => {
    const newState = {
      ...getState().salary.salaryLines,
      filter: {
        $and: [
          { year: { $eq: +getState().salary.activeYear } },
          { month: { $eq: +getState().salary.activeMonth } },
        ],
      },
    };
    const path = isAdmin
      ? `branches/${
          getState().salary.activeBranch
        }/salary/${employeeId}/salary_lines`
      : `salary/${employeeId}/salary_lines`;

    const scopePrefix = getState().user.scopePrefix;
    dispatch(setSalaryLines(newState));
    if (!isAdmin || (isAdmin && getState().salary.activeBranch)) {
      dispatch(fetchSalaryLines({ ...newState, scopePrefix, path }));
    }
  };

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().salary.page));
};
