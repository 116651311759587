import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  listPaymentRequests,
  generateProspectuses,
  dryRunGenerateProspectuses,
  updatePaymentRequest,
} from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";
import moment from "moment";
import { withNotifications } from "../notifications/notificationsSlice";

export const fetchPage = createAsyncThunk(
  "paymentRequests/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await listPaymentRequests(listParams);
    if (error) {
      thunkAPI.dispatch(
        notifyError({ title: "Load payment requests", message: error })
      );
    }
    return data;
  }
);

export const createPayment = createAsyncThunk(
  "paymentRequests/create",
  withNotifications(
    generateProspectuses,
    "create_payment",
    "create_payment_success",
    "create_payment_error"
  )
);

export const createDryRunPayment = createAsyncThunk(
  "paymentRequests/dryRun",
  withNotifications(
    dryRunGenerateProspectuses,
    "dry_run",
    "dry_run_success",
    "dry_run_error"
  )
);

export const updatePayment = createAsyncThunk(
  "paymentRequests/update",
  withNotifications(
    updatePaymentRequest,
    "update_payment",
    "update_payment_success",
    "update_payment_error"
  )
);

const initialPageSize = 10;
const initialState = {
  activeYear: moment().subtract(1, "months").format("YYYY"),
  activeMonth: moment().subtract(1, "months").format("M"),
  activeBranch: null,
  activeClient: null,
  activeCategory: null,
  generatedProspectuses: [],
  dryRunProspectuses: [],
  clients: [],
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {},
    items: [],
  },
};

const paymentRequestsSlice = createSlice({
  name: "paymentRequests",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setYear(state, action) {
      const { year } = action.payload;
      state.activeYear = year;
      state.page = { ...state.page, current: 1 };
    },
    setMonth(state, action) {
      const { month } = action.payload;
      state.activeMonth = month;
      state.page = { ...state.page, current: 1 };
    },
    setBranch(state, action) {
      const { branch } = action.payload;
      state.activeBranch = branch;
      state.page = { ...state.page, current: 1 };
    },
    setClient(state, action) {
      const { client } = action.payload;
      state.activeClient = client;
      state.page = { ...state.page, current: 1 };
    },
    setClientsFlag(state, action) {
      const { clients } = action.payload;
      state.clients = clients;
      state.page = { ...state.page, current: 1 };
    },
    setCategory(state, action) {
      const { category } = action.payload;
      state.activeCategory = category;
      state.page = { ...state.page, current: 1 };
    },
    reset(state, action) {
      state.activeYear = initialState.activeYear;
      state.activeMonth = initialState.activeMonth;
      state.activeBranch = initialState.activeBranch;
      state.activeClient = initialState.activeClient;
      state.activeCategory = initialState.activeCategory;
      state.generatedProspectuses = initialState.generatedProspectuses;
      state.dryRunProspectuses = initialState.dryRunProspectuses;
      state.page = initialState.page;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createPayment.fulfilled]: (state, action) => {
      state.generatedProspectuses = action.payload.data.items;
    },
    [createPayment.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [createDryRunPayment.fulfilled]: (state, action) => {
      state.dryRunProspectuses = action.payload.data.items;
    },
    [createDryRunPayment.rejected]: (state, action) => {
      state.error = action.payload;
    },
    [updatePayment.fulfilled]: (state, action) => {},
    [updatePayment.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setPage,
  setYear,
  setMonth,
  setBranch,
  setClient,
  setCategory,
  setClientsFlag,
  reset,
} = paymentRequestsSlice.actions;

export default paymentRequestsSlice.reducer;

export const loadPage = (params, query) => (dispatch, getState) => {
  const newState = {
    ...getState().paymentRequests.page,
    ...params,
    query: {
      year:
        getState().paymentRequests.activeYear &&
        +getState().paymentRequests.activeYear,
      month:
        getState().paymentRequests.activeMonth &&
        +getState().paymentRequests.activeMonth,
      client_id: getState().paymentRequests.activeClient,
      client_category_id: +getState().paymentRequests.activeCategory,
      clients: getState().paymentRequests.clients,
    },
  };
  const path =
    getState().user.scopePrefix === "" &&
    !getState().paymentRequests.activeBranch
      ? null
      : getState().paymentRequests.activeBranch
      ? `branches/${getState().paymentRequests.activeBranch}/accounting/dry_run`
      : "accounting/dry_run";
  const scopePrefix = getState().user.scopePrefix;
  dispatch(setPage(newState));
  if (path) {
    dispatch(fetchPage({ ...newState, scopePrefix, path }));
  }
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().paymentRequests.page));
};

export const createPaymentRequests = () => (dispatch, getState) => {
  const path = getState().paymentRequests.activeBranch
    ? `branches/${getState().paymentRequests.activeBranch}/`
    : getState().user.scopePrefix;
  const params = {
    path: path,
    year:
      getState().paymentRequests.activeYear &&
      +getState().paymentRequests.activeYear,
    month:
      getState().paymentRequests.activeMonth &&
      +getState().paymentRequests.activeMonth,
    client_id: getState().paymentRequests.activeClient,
    client_category_id: +getState().paymentRequests.activeCategory,
    clients: getState().paymentRequests.clients,
  };
  return dispatch(createPayment(params));
};

export const createDryRunPaymentRequests = () => (dispatch, getState) => {
  const path = getState().paymentRequests.activeBranch
    ? `branches/${getState().paymentRequests.activeBranch}/`
    : getState().user.scopePrefix;

  const params = {
    path: path,
    year:
      getState().paymentRequests.activeYear &&
      +getState().paymentRequests.activeYear,
    month:
      getState().paymentRequests.activeMonth &&
      +getState().paymentRequests.activeMonth,
    client_id: getState().paymentRequests.activeClient,
    client_category_id: +getState().paymentRequests.activeCategory,
    clients: getState().paymentRequests.clients,
  };
  return dispatch(createDryRunPayment(params));
};
