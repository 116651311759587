import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchList } from "../../api/server";
import { notifyError } from "../notifications/notificationsSlice";
import moment from "moment";

export const fetchPage = createAsyncThunk(
  "assets/loadPage",
  async (listParams, thunkAPI) => {
    console.log(listParams);

    const { error, data } = await fetchList("assets", listParams);
    if (error) {
      thunkAPI.dispatch(notifyError({ title: "Load assets", message: error }));
    }
    return data;
  }
);

const initialPageSize = 10;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {
      field: "created_at",
      order: "descend",
    },
    items: [],
    query: {
      categories: [],
    },
    filters: {
      category_id: [],
      sub_category_id: [],
      owner_type: [],
      status_id: [],
    },
    finalfilters: {
      category_id: [],
      sub_category_id: [],
      owner_type: [],
      status_id: [],
    },
  },
  from: null,
  to: null,
  activeBranch: null,
  scope: "",
  changedTimestamp: null,
  reloadTimestamp: null,
  showInactive: false,
};

const assetsSlice = createSlice({
  name: "assets",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setTimeFrame(state, action) {
      const { from, to } = action.payload;
      state.from = from;
      state.to = to;
    },
    setBranch(state, action) {
      state.activeBranch = action.payload;
    },
    setScope(state, action) {
      state.scope = action.payload;
    },
    setChangedTimestamp(state, action) {
      state.changedTimestamp = action.payload;
    },
    setReloadTimestamp(state, action) {
      state.reloadTimestamp = action.payload;
    },
    setShowInactive(state, action) {
      state.showInactive = action.payload;
    },
    reset(state, action) {
      state.page = initialState.page;
      state.from = initialState.from;
      state.to = initialState.to;
      state.activeBranch = initialState.activeBranch;
      state.scope = initialState.scope;
      state.changedTimestamp = initialState.changedTimestamp;
      state.showInactive = initialState.showInactive;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setPage,
  setTimeFrame,
  setBranch,
  setScope,
  setChangedTimestamp,
  setReloadTimestamp,
  setShowInactive,
  reset,
} = assetsSlice.actions;

export default assetsSlice.reducer;

export const changeTimeFrame = (from, to) => (dispatch, getState) => {
  const { filter } = getState().assets.page;
  dispatch(setTimeFrame({ from, to }));
  dispatch(loadPage({ current: 1, filter: { ...filter } }));
};

export const changeBranch = (branch) => (dispatch, getState) => {
  const { filter } = getState().assets.page;
  dispatch(setBranch(branch));
  dispatch(loadPage({ current: 1, filter: { ...filter } }));
};

export const changeScope = (scope) => (dispatch, getState) => {
  const { filter } = getState().assets.page;
  dispatch(setScope(scope));
  dispatch(loadPage({ current: 1, filter: { ...filter } }));
};

export const loadPage = (params, query) => (dispatch, getState) => {
  const { from, to } = getState().assets;
  let filter = {
    freeText: params.filter.freeText,
  };
  let conditions = [];
  if (params && params.filter["$and"]) {
    conditions = params.filter["$and"];
  }
  conditions = [
    ...conditions.filter((i) => !Object.keys(i).includes("created_at")),
  ];
  if (from && to) {
    conditions = [
      ...conditions,
      {
        created_at: {
          $gte: from,
          $lt: to,
        },
      },
    ];
  }
  filter["$and"] = conditions;
  let newParams = {
    ...params,
    filter,
  };
  const newState = {
    ...getState().assets.page,
    ...newParams,
  };

  dispatch(setPage(newState));
};

export const reload = () => (dispatch, getState) => {
  return dispatch(setReloadTimestamp(moment().format()));
};
