import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import axios from "axios";
import { message } from "antd";
import {
  multiFactor,
  TotpMultiFactorGenerator,
  getMultiFactorResolver,
} from "firebase/auth";
import {
  setUser,
  fetchUserProfile,
  setSigningOutProcess,
  setEnrolledTotpModalVidible,
  setFirebaseUser,
  setEmailFactorModalVisible,
} from "../features/user/UserSlice";
import { loadRecord } from "../features/organization/organizationSlice";
import {
  getCurrentTenant,
  login_with_token,
  remove_token,
} from "../api/server";

import store from "../redux/store";

export const configurations = {
  production: {
    apiKey: "AIzaSyDs2QkqANgCTQIcThQFCiNkxcKGPVkS_lM",
    authDomain: "steam-capsule-316104.firebaseapp.com",
    projectId: "steam-capsule-316104",
    storageBucket: "steam-capsule-316104.appspot.com",
    messagingSenderId: "793066330760",
    appId: "1:793066330760:web:c38f3c358a13eb50de1d3d",
    masterTenant: "master-ujuwd",
    masterDomain: "master.a-dreams.com",
  },
  matav: {
    apiKey: "AIzaSyC4XYWtPgsMSIHkzJrRm4lz4Osae97BY2Y",
    authDomain: "coherent-medium-332412.firebaseapp.com",
    projectId: "coherent-medium-332412",
    storageBucket: "coherent-medium-332412.appspot.com",
    messagingSenderId: "65563686053",
    appId: "1:65563686053:web:118095370539a6dbd5494c",
    masterTenant: "master-zeevx",
    masterDomain: "dreams-master.matav.org.il",
  },
  dev: {
    apiKey: "AIzaSyB9qwava8-KbE--nFcoMVDcsgvJAqgmxLc",
    authDomain: "dreams-da917.firebaseapp.com",
    databaseURL: "https://dreams-da917.firebaseio.com",
    projectId: "dreams-da917",
    storageBucket: "dreams-da917.appspot.com",
    messagingSenderId: "320497435948",
    appId: "1:320497435948:web:229b0bae39762d95bdd889",
    masterTenant: "test-master-mw5sz",
    masterDomain: "localhost:3000",
  },
};

export const firebaseConfig =
  configurations[process.env.REACT_APP_CONFIG || "dev"];

export const firebaseApp = firebase.initializeApp(firebaseConfig);
firebaseApp.auth().languageCode = "he";

axios.interceptors.request.use(
  (request) => {
    const currentUser = firebaseApp.auth().currentUser;
    if (!currentUser) {
      return request;
    }
    return currentUser
      .getIdToken()
      .then((token) => {
        request.headers = {
          ...request.headers,
          Authorization: `Bearer ${token}`,
          dreamsdesktop: "dreamsdesktop",
        };
        return request;
      })
      .catch(() => request);
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data === "login-required"
    ) {
      return firebaseApp
        .auth()
        .signOut()
        .then(() => {
          message.error("המשתמש לא מאומת"); // User is unauthorized
        })
        .catch((error) => {
          console.error("Error during sign out:", error);
          return Promise.reject(error);
        });
    }
    return Promise.reject(error);
  }
);

class Auth {
  select(state) {
    return state.registration.registrationInProgress;
  }
  constructor() {
    firebaseApp.auth().onAuthStateChanged(async (user) => {
      if (store.getState().registration.registrationInProgress) {
        return;
      }
      if (!user) {
        //user is logged out
        store.dispatch(setUser(null));
        remove_token();
        return;
      } else {
        store.dispatch(setFirebaseUser(user));
      }
    });
  }

  async handleUserSignIn(user) {
    if (process.env.REACT_APP_BUILD_TARGET !== "tenants") {
      const action = await store.dispatch(fetchUserProfile());
      if (action.error) throw new Error("UserProfile fetch failed");
      return await store.dispatch(loadRecord());
    } else {
      store.dispatch(
        setUser({
          displayName: user?.displayName,
          email: user?.email,
        })
      );
    }
  }

  async loginWithUserToken({ user, code }) {
    try {
      const token = await user.getIdToken();
      const loginResponse = await login_with_token({ token, code });
      const { message } = loginResponse;

      if (message === "code_sent_to_email") {
        store.dispatch(setEmailFactorModalVisible(true));
        return { status: "code_sent" };
      } else if (message === "ok") {
        store.dispatch(setEmailFactorModalVisible(false));
        await this.handleUserSignIn(user);
        return { status: "success" };
      } else {
        throw new Error("Unexpected response from server");
      }
    } catch (error) {
      console.error("Error in loginWithUserToken:", error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        throw new Error(
          `Server error: ${
            error.response.data.message || error.response.statusText
          }`
        );
      } else if (error.request) {
        // The request was made but no response was received
        throw new Error("No response received from server");
      } else {
        // Something happened in setting up the request that triggered an Error
        throw new Error(`Request setup error: ${error.message}`);
      }
    }
  }

  async signInWithProvider(provider, providerTenant) {
    var azureProvider = new firebase.auth.OAuthProvider(provider);
    azureProvider.setCustomParameters({
      tenant: providerTenant,
    });
    let tenant = await getCurrentTenant();
    const auth = firebaseApp.auth();
    auth.tenantId = tenant.name;
    return firebase.auth().signInWithPopup(azureProvider);
  }

  async getToken() {
    const currentUser = firebaseApp.auth().currentUser;
    if (!currentUser) {
      return null;
    }
    return await currentUser.getIdToken();
  }

  async signInWithEmailAndPassword(
    email,
    password,
    isSystemUser,
    ref,
    recaptcha
  ) {
    let tenant = {};
    if (process.env.REACT_APP_BUILD_TARGET === "tenants" || isSystemUser) {
      tenant["name"] =
        configurations[process.env.REACT_APP_CONFIG || "dev"].masterTenant;
    } else {
      tenant = await getCurrentTenant();
    }
    const auth = firebaseApp.auth();
    auth.tenantId = tenant.name;
    var recaptchaVerifier = recaptcha
      ? recaptcha
      : new firebase.auth.RecaptchaVerifier(ref, {
          size: "invisible",
        });

    var resolver;

    try {
      await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      const userInfo = await auth.signInWithEmailAndPassword(email, password);
      if (userInfo) {
        //temporary for users totp enrollments before totp 2factor enforcment
        // const hasEnrolledTotpFactor =
        //   userInfo?.multiFactor?.enrolledFactors.some(
        //     (factor) => factor.factorId === "totp"
        //   );
        // if (
        //   !(process.env.REACT_APP_BUILD_TARGET === "tenants" || isSystemUser) &&
        //   !hasEnrolledTotpFactor
        // ) {
        //   store.dispatch(setEnrolledTotpModalVidible(true));
        // }

        const user = userInfo.user;
        if (!process.env.REACT_APP_BUILD_TARGET === "tenants") {
          this.loginWithUserToken({ user });
        } else {
          this.handleUserSignIn(user);
        }
        return {
          is2FA: false,
          userInfo,
          resolver: null,
          verificationId: null,
          error: null,
        };
      }
    } catch (error) {
      if (error.code === "auth/multi-factor-auth-required") {
        const resolver = getMultiFactorResolver(auth, error);
        return {
          is2FA: true,
          userInfo: null,
          resolver,
          recaptchaVerifier,
          error: null,
        };
      } else {
        return {
          is2FA: false,
          userInfo: null,
          resolver: null,
          verificationId: null,
          error,
        };
        // Handle other errors such as wrong password.
      }
    } finally {
    }
  }

  async generateTotpSecret() {
    const currentUser = firebaseApp.auth().currentUser;
    const multiFactorSession = await multiFactor(currentUser).getSession();
    const totpSecret = await TotpMultiFactorGenerator.generateSecret(
      multiFactorSession
    );
    return { totpSecret, currentUser };
  }

  async enrollTotp(secret, verificationCode) {
    const currentUser = firebaseApp.auth().currentUser;
    const multiFactorAssertion =
      TotpMultiFactorGenerator.assertionForEnrollment(secret, verificationCode);
    try {
      // Enroll to TOTP MFA.
      const data = await multiFactor(currentUser).enroll(
        multiFactorAssertion,
        "totp"
      );
      return {};
    } catch (error) {
      return { error: error.code };
    }
  }

  async unEnrollTotp() {
    const currentUser = firebaseApp.auth().currentUser;
    const multiFactorUser = multiFactor(currentUser);
    const totpFactor = multiFactorUser.enrolledFactors.find(
      (factor) => factor.factorId === TotpMultiFactorGenerator.FACTOR_ID
    );
    try {
      // Unenroll from TOTP MFA.
      const data = await multiFactor(currentUser).unenroll(totpFactor);
      return {};
    } catch (error) {
      return { error: error.code };
    }
  }

  async send2FACode(resolver, recaptchaVerifier) {
    var phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    };
    var phoneAuthProvider = new firebase.auth.PhoneAuthProvider();
    // Send SMS verification code
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(
      phoneInfoOptions,
      recaptchaVerifier
    );
    return {
      verificationId,
      error: null,
    };
  }

  async confirm2FATotpCode(resolver, factorUid, otp) {
    // Ask the user for the OTP code from the TOTP app.
    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
      factorUid,
      otp
    );
    // Finalize the sign-in.
    const userCredential = await resolver.resolveSignIn(multiFactorAssertion);
    const user = userCredential.user;
    this.loginWithUserToken({ user });

    return userCredential;
  }

  async confirm2FAPhoneCode(resolver, verificationId, verificationCode) {
    var cred = firebase.auth.PhoneAuthProvider.credential(
      verificationId,
      verificationCode
    );
    var multiFactorAssertion =
      firebase.auth.PhoneMultiFactorGenerator.assertion(cred);
    // Complete sign-in.
    const userCredential = await resolver.resolveSignIn(multiFactorAssertion);

    const user = userCredential.user;
    this.loginWithUserToken({ user });
    return userCredential;
  }

  async sendPasswordResetEmail(email) {
    let tenant = {};
    if (process.env.REACT_APP_BUILD_TARGET === "tenants") {
      tenant["name"] =
        configurations[process.env.REACT_APP_CONFIG || "dev"].masterTenant;
    } else {
      tenant = await getCurrentTenant();
    }
    firebaseApp.auth().tenantId = tenant.name;
    return firebaseApp.auth().sendPasswordResetEmail(email);
  }

  changePassword(tenantId, actionCode, password) {
    const auth = firebaseApp.auth();
    auth.tenantId = tenantId;
    return auth
      .verifyPasswordResetCode(actionCode)
      .then((email) => {
        auth.confirmPasswordReset(actionCode, password);
      })
      .catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
      });
  }

  async linkProvider(
    tenantId,
    authProvider,
    providerTenant,
    email,
    href,
    history
  ) {
    const auth = firebaseApp.auth();
    var provider = new firebase.auth.OAuthProvider(authProvider);
    provider.setCustomParameters({
      tenant: providerTenant,
    });
    auth.tenantId = tenantId;
    await auth.signInWithEmailLink(email, href);
    const result = await auth.currentUser.linkWithPopup(provider);
    var credential = result.credential;
    await auth.signInWithCredential(credential);
  }

  linkAccount(email, password, history) {
    var credential = firebase.auth.EmailAuthProvider.credential(
      email,
      password
    );
    firebaseApp
      .auth()
      .currentUser.linkWithCredential(credential)
      .then(function (usercred) {
        var user = usercred.user;
        console.log("Account linking success", user);
        const url = "/";
        history.push(url);
      })
      .catch(function (error) {
        console.log("Account linking error", error);
      });
  }

  async signOut() {
    store.dispatch(setSigningOutProcess(true));
    try {
      await firebaseApp.auth().signOut();
      store.dispatch(setUser(null));
    } catch (error) {
      console.log("Sign out error", error);
    } finally {
      store.dispatch(setSigningOutProcess(false));
    }
  }

  async signInWithLink(tenantId, email) {
    const auth = firebaseApp.auth();
    auth.tenantId = tenantId;
    return auth.signInWithEmailLink(email);
  }
}

export default new Auth();
