import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createPatient,
  updatePatient,
  deletePatient,
  getPatient,
  getGlobalPatient,
  activatePatient,
  deactivatePatient,
  transferPatientToBranch,
} from "../../api/server";
import { unflatten } from "flat";
import NotificationError from "../notifications/error";
import {
  notifySuccess,
  withNotifications,
} from "../notifications/notificationsSlice";

const initialState = {
  error: null,
  activePatient: {},
  changedItems: {
    changed: [],
    values: {},
  },
  assets: [],
};

export const loadRecord = createAsyncThunk(
  "patientDetails/loadPatient",
  async (params, thunkAPI) => {
    return getPatient(params).then(({ error, data }) => {
      if (error) {
        // throw new NotificationError("Load Patient", error);
      }
      return data;
    });
  }
);

export const loadGlobalPatient = createAsyncThunk(
  "patientDetails/loadGlobalPatient",
  async (params, thunkAPI) => {
    return getGlobalPatient(params.scopePrefix, params.filter).then(
      ({ error, data }) => {
        if (error) {
          // throw new NotificationError("Load employee", error);
        }
        return data;
      }
    );
  }
);

export const commitChanges = createAsyncThunk(
  "patientDetails/update",
  withNotifications(
    async (params, thunkAPI) => {
      const { patientDetails } = thunkAPI.getState();
      const { activePatient } = patientDetails;
      const { branch: branchId, id } = activePatient;
      const {
        changedItems: { changed, values },
      } = patientDetails;
      let toUpdate = changed.reduce((acc, key) => {
        acc[key] = values[key] === undefined ? null : values[key];
        return acc;
      }, {});
      if (changed.includes("local_id_number")) {
        toUpdate["local_id_number"] = toUpdate["local_id_number"].padStart(
          9,
          "0"
        );
      }
      return updatePatient({
        patient: { id, ...unflatten(toUpdate) },
        branchId,
        fromCrm: params && params.fromCrm,
      });
    },
    "save_patient",
    "save_patient_success",
    "save_patient_error"
  )
);

export const updateOpportunityStatus = createAsyncThunk(
  "patientDetails/updateOpportunityStatus",
  withNotifications(
    updatePatient,
    "update_opportunity_status",
    "update_opportunity_status_success",
    "update_opportunity_status_error"
  )
);

export const patientActivation = createAsyncThunk(
  "patientDetails/activation",
  withNotifications(
    async (params, thunkAPI) => {
      const { status_id } = params;
      const { patientDetails } = thunkAPI.getState();
      const { activePatient } = patientDetails;
      const { branch: branchId, id } = activePatient;
      return updatePatient({
        patient: { id, status_id },
        branchId,
      });
    },
    "change_patient_status",
    "change_patient_status_success",
    "change_patient_status_error"
  )
);

export const createRecord = createAsyncThunk(
  "patientDetails/create",
  withNotifications(
    createPatient,
    "create_patient",
    "create_patient_success",
    "create_patient_error"
  )
);

export const activateRecord = createAsyncThunk(
  "patientDetails/activate",
  withNotifications(
    activatePatient,
    "activate_patient",
    "activate_patient_success",
    "activate_patient_error"
  )
);

export const deactivateRecord = createAsyncThunk(
  "patientDetails/deactivate",
  withNotifications(
    deactivatePatient,
    "deactivate_patient",
    "deactivate_patient_success",
    "deactivate_patient_error"
  )
);

export const deleteRecord = createAsyncThunk(
  "patientDetails/delete",
  async (params, thunkAPI) => {
    return deletePatient(params).then(({ error, data }) => {
      if (error) {
        throw new NotificationError("Delete patient", error);
      }
      thunkAPI.dispatch(
        notifySuccess({
          title: "Delete patient",
          message: "patient was deleted succesfully",
        })
      );
      return data;
    });
  }
);

export const transferPatient = createAsyncThunk(
  "transferPatient/transfer",
  withNotifications(
    transferPatientToBranch,
    "transfer_patient",
    "transfer_patient_success",
    "transfer_patient_error"
  )
);

const patientDetailsSlice = createSlice({
  name: "patientDetails",
  initialState: initialState,
  reducers: {
    setActivePatient(state, action) {
      const { record } = action.payload;
      state.activePatient = record;
    },
    setChangedFormItems(state, action) {
      const { changed, values } = action.payload;

      state.changedItems.changed = changed;
      state.changedItems.values = values;
    },
    setActiveGeo(state, action) {
      const { record } = action.payload;
      state.activeGeo = record;
    },
    reset(state, action) {
      state.error = null;
      state.activePatient = {};
      state.activeGeo = {
        longtitude: 35.21371,
        latitude: 31.768319,
        address_line: null,
      };
    },
  },
  extraReducers: {
    [loadRecord.fulfilled]: (state, action) => {
      state.changedItems = { changed: [], values: {} };
      state.activePatient = action.payload;
    },
    [commitChanges.fulfilled]: (state, { payload: { error, data } }) => {
      state.activePatient = data;
      state.changedItems = { changed: [], values: {} };
      state.error = error;
    },
    [commitChanges.fulfilled]: (state, { payload: { error, data } }) => {
      state.activePatient = data;
      state.changedItems = { changed: [], values: {} };
      state.error = error;
    },
    [updateOpportunityStatus.fulfilled]: (
      state,
      { payload: { error, data } }
    ) => {
      state.activePatient = data;
      state.changedItems = { changed: [], values: {} };
      state.error = error;
    },
    [patientActivation.rejected]: (state, action) => {
      state.error = action.error;
    },
    [createRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activePatient = data;
      state.error = error;
    },
    [createRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [activateRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activePatient = data;
      state.error = error;
    },
    [deactivateRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activePatient = data;
      state.error = error;
    },
    [deleteRecord.fulfilled]: (state, action) => {
      state.activePatient = {};
    },
  },
});

export const { setActivePatient, setActiveGeo, reset, setChangedFormItems } =
  patientDetailsSlice.actions;

export default patientDetailsSlice.reducer;
