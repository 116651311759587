import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers/index";
import errorMiddleware from "./reducers/error_middleware";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  //middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(errorMiddleware)
});

export default store;
