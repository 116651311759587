import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createContact,
  updateContact,
  deleteContact,
  getContact,
} from "../../../api/server";
import { unflatten } from "flat";
import NotificationError from "../../notifications/error";
import {
  notifySuccess,
  withNotifications,
} from "../../notifications/notificationsSlice";

const initialState = {
  error: null,
  activeContact: {},
  changedItems: {
    changed: [],
    values: {},
  },
  assets: [],
};

export const loadRecord = createAsyncThunk(
  "contact/load",
  async (params, thunkAPI) => {
    return getContact(params).then(({ error, data }) => {
      if (error) {
        // throw new NotificationError("Load contact", error);
      }
      return data;
    });
  }
);

export const commitChanges = createAsyncThunk(
  "contact/update",
  withNotifications(
    async (params, thunkAPI) => {
      const { contact } = thunkAPI.getState();
      const { activeContact } = contact;
      const { id } = activeContact;
      const {
        changedItems: { changed, values },
      } = contact;
      const toUpdate = changed.reduce((acc, key) => {
        acc[key] = values[key] === undefined ? null : values[key];
        return acc;
      }, {});
      return updateContact({
        contact: { id, ...unflatten(toUpdate) },
      });
    },
    "save_contact",
    "save_contact_success",
    "save_contact_error"
  )
);

export const createRecord = createAsyncThunk(
  "contact/create",
  withNotifications(
    createContact,
    "create_contact",
    "create_contact_success",
    "create_contact_error"
  )
);

export const deleteRecord = createAsyncThunk(
  "contact/delete",
  async (params, thunkAPI) => {
    return deleteContact(params).then(({ error, data }) => {
      if (error) {
        throw new NotificationError("Delete contact", error);
      }
      thunkAPI.dispatch(
        notifySuccess({
          title: "Delete contact",
          message: "contact was deleted succesfully",
        })
      );
      return data;
    });
  }
);

const contactSlice = createSlice({
  name: "contact",
  initialState: initialState,
  reducers: {
    setActiveContact(state, action) {
      const { record } = action.payload;
      state.activeContact = record;
    },
    setChangedFormItems(state, action) {
      const { changed, values } = action.payload;
      state.changedItems.changed = changed;
      state.changedItems.values = values;
    },
    reset(state, action) {
      state.error = null;
      state.activeContact = {};
      state.activeGeo = {
        longtitude: 35.21371,
        latitude: 31.768319,
        address_line: null,
      };
    },
  },
  extraReducers: {
    [loadRecord.fulfilled]: (state, action) => {
      state.changedItems = { changed: [], values: {} };
      state.activeContact = action.payload;
    },
    [commitChanges.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeContact = data;
      state.changedItems = { changed: [], values: {} };
      state.error = error;
    },
    [createRecord.fulfilled]: (state, { payload: { error, data } }) => {
      state.activeContact = data;
      state.error = error;
    },
    [createRecord.rejected]: (state, action) => {
      state.error = action.error;
    },
    [deleteRecord.fulfilled]: (state, action) => {
      state.activeContact = {};
    },
  },
});

export const { setActiveContact, reset, setChangedFormItems } =
  contactSlice.actions;

export default contactSlice.reducer;
