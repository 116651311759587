import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { listTasks, createTask, updateTask } from "../../api/server";
import {
  notifyError,
  withNotifications,
} from "../notifications/notificationsSlice";

export const fetchPage = createAsyncThunk(
  "tasks/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await listTasks(listParams);
    if (error) {
      thunkAPI.dispatch(notifyError({ title: "Load tasks", message: error }));
    }
    return data;
  }
);

export const createNewTask = createAsyncThunk(
  "tasks/create",
  withNotifications(
    createTask,
    "create_new_task",
    "create_new_task_success",
    "create_new_task_error"
  )
);

export const updateNewTask = createAsyncThunk(
  "tasks/update",
  withNotifications(
    updateTask,
    "update_new_task",
    "update_new_task_success",
    "update_new_task_error"
  )
);

const initialPageSize = 10;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: undefined,
      $or: [{ status: "opened" }, { status: "inprogress" }],
    },
    order: { field: "created_at", order: "descend" },
    filters: {},
    items: [],
  },
  statusFilter: { $or: [{ status: "opened" }, { status: "inprogress" }] },
  assignedFilter: null,
  showNonActive: false,
  blocked: true,
  templates: {
    items: [],
  },
};

const tasksSlice = createSlice({
  name: "tasks",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    setStatusFilter(state, action) {
      const { filter } = action.payload;
      state.statusFilter = filter;
      state.page = {
        ...state.page,
        filter: { ...state.page.filter, $or: filter["$or"] },
      };
    },
    setAssignedFilter(state, action) {
      const { filter } = action.payload;
      state.assignedFilter = filter;
      state.blocked = false;
    },
    setTextFilter(state, action) {
      const { filter } = action.payload;
      state.page = {
        ...state.page,
        filter: { ...state.page.filter, freeText: filter },
      };
    },
    setNonActive(state, action) {
      const { showNonActive } = action.payload;
      state.showNonActive = showNonActive;
    },
    reset(state, action) {
      state.page = initialState.page;
      state.statusFilter = initialState.statusFilter;
      state.assignedFilter = initialState.assignedFilter;
      state.showNonActive = initialState.showNonActive;
      state.blocked = initialState.blocked;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setPage,
  setStatusFilter,
  setAssignedFilter,
  setTextFilter,
  setNonActive,
  reset,
} = tasksSlice.actions;

export default tasksSlice.reducer;

export const changePage = (params, query, replace) => (dispatch, getState) => {
  const newState = {
    ...getState().tasks.page,
    ...params,
    order: params.order,
  };
  dispatch(setPage(newState));
  let statusesFilters = [];
  const statuses = getState().tasks.showNonActive
    ? [
        { status: "opened" },
        { status: "inprogress" },
        { status: "cancelled" },
        { status: "completed" },
      ]
    : [{ status: "opened" }, { status: "inprogress" }];
  statuses.forEach((item, i) => {
    const found =
      params.finalfilters && params.finalfilters["status"]
        ? params.finalfilters["status"].find((t) => t === item.status)
        : null;
    if (found) {
      statusesFilters.push(item);
    }
  });
  dispatch(
    setStatusFilter({
      filter:
        statusesFilters.length > 0
          ? { $or: statusesFilters }
          : { $or: statuses },
    })
  );
};

export const loadPage = () => (dispatch, getState) => {
  const filters = [];
  if (getState().tasks.assignedFilter) {
    filters.push(getState().tasks.assignedFilter);
  }
  if (getState().tasks.statusFilter["$or"]) {
    filters.push({ ...getState().tasks.statusFilter });
  }
  const newState = {
    ...getState().tasks.page,
    filter: {
      ...getState().tasks.page.filter,
      $and: filters,
    },
  };
  const scopePrefix = getState().user.scopePrefix;
  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState, scopePrefix }));
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().tasks.page));
};
