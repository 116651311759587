import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchList,
  updateRecord,
  createRecord,
  getRecord,
} from "../../api/server";
import {
  notifySuccess,
  withNotifications,
  notifyError,
} from "../notifications/notificationsSlice";

export const fetchPage = createAsyncThunk(
  "reports/loadPage",
  async (listParams, thunkAPI) => {
    const { error, data } = await fetchList("reports", listParams);
    if (error) {
      thunkAPI.dispatch(notifyError({ title: "Load reports", message: error }));
    }
    return data;
  }
);

export const loadReport = createAsyncThunk(
  "reports/loadReport",
  async (params, thunkAPI) => {
    return getRecord("reports", params).then(({ error, data }) => {
      if (error) {
        // throw new NotificationError("Load Patient", error);
      }
      return data;
    });
  }
);

export const updateReport = createAsyncThunk(
  "reports/update",
  withNotifications(
    (rec) => updateRecord("reports", rec),
    "save_report",
    "save_report_success",
    "save_report_error"
  )
);

export const createReport = createAsyncThunk(
  "reports/create",
  withNotifications(
    (rec) => createRecord("reports", rec),
    "create_report",
    "create_report_success",
    "create_report_error"
  )
);

const initialPageSize = 10;
const initialState = {
  page: {
    current: 1,
    pageSize: initialPageSize,
    filter: {
      freeText: null,
    },
    order: {
      field: "updated_at",
      order: "descend",
    },
    items: [],
    query: {
      categories: [],
    },
    filters: {
      categories: [],
    },
    finalfilters: {
      categories: [],
    },
  },
};

const reportsSlice = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    setPage(state, { payload: page }) {
      state.page = page;
    },
    reset(state, action) {
      state.page = initialState.page;
    },
  },
  extraReducers: {
    [fetchPage.fulfilled]: (state, action) => {
      state.page = { ...state.page, ...action.payload };
    },
    [fetchPage.rejected]: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { setPage, reset } = reportsSlice.actions;

export default reportsSlice.reducer;

export const loadPage = (params) => (dispatch, getState) => {
  const { finalfilters } = params;
  const newState = {
    ...getState().reports.page,
    ...params,
    filter: { freeText: params && params.filter ? params.filter.freeText : "" },
    query: {
      categories: finalfilters
        ? finalfilters.categories
        : getState().reports.page.finalfilters.categories,
    },
  };
  const scopePrefix = getState().user.scopePrefix;
  dispatch(setPage(newState));
  dispatch(fetchPage({ ...newState, scopePrefix }));
};

export const reload = () => (dispatch, getState) => {
  return dispatch(loadPage(getState().reports.page));
};
